<template>
  <div>
    <!--    <AdminQuotaTable v-if="getUserRoles.find(el => el === 'ADMIN_ROLE')"/>-->
    <!--    <QuotaContact v-else-if="getUserRoles.find(el => el === 'FOUNDER_ROLE' || el === 'REGION_ROLE')"/>-->
    <AdminFounders v-if="getUserRoles.find(el => el === 'ADMIN_ROLE')"/>
    <OrganizationQuotaTable v-else-if="getUserRoles.find(el => el === 'ORGANIZATION_ROLE')"/>
    <PropContact v-else-if="getUserRoles.find(el => el === 'FOUNDER_ROLE' || el === 'REGION_ROLE')"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
// import QuotaContact from "@/views/QuotaContact";
// import AdminQuotaTable from "@/views/AdminQuotaTable";
import OrganizationQuotaTable from "@/views/OrganizationQuotaTable";
import PropContact from "@/views/private/PropContact";
import AdminFounders from "@/views/private/AdminFounders";

export default {
  components: {
    AdminFounders,
    PropContact,
    OrganizationQuotaTable,
    // AdminQuotaTable,
    // QuotaContact,
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles'
    }),
  },
}

</script>

<style lang="sass">
@import "@/assets/styles/utils/variables"
.section-preview_lk
  background: #fff
  border-radius: 10px
  border: 1px solid #cdd1d4
  margin-bottom: 20px

.lk-list_flex_start
  justify-content: flex-start
</style>